const deptMixins = {
  data() {
    return {
      tableData: [],
      rolesList: [],
      isDisabled: false,
      isDisabledRoles: false,
      placeholderTxt: "加载中..."
    };
  },
  created() { },
  methods: {
    getRoles() {
      this.isDisabledRoles = true;
      this.$emit("isDisabledRolesFun", true);
      //获取角色列表
      this.$store.dispatch("getRolesList").then(res => {
        if (res) {
          console.log(this.role_id);
          this.roles = this.role_id;
          this.rolesList = res;

          this.isDisabledRoles = false;
          this.$emit("isDisabledRolesFun", false);
        }
      });
    },
    async getDepartList() {
      this.placeholderTxt = "加载中";
      this.isDisabled = true;
      this.$emit("isDisabledFun", true);
      this.$store.dispatch("getDepartments").then(res => {
        if (res) {
          this.tableData = this.filterDep(res);
          let tableData = JSON.parse(JSON.stringify(this.tableData));
          //if (this.multiple) {
          let newArr = [];

          let chaifen = this.chaifen(tableData);

          for (let i in this.item) {
            let item = this.item[i];
            for (let j in chaifen) {
              let item1 = chaifen[j];
              if (item1.id * 1 == item * 1) {
                newArr.push(this.zhaoFu(item1, chaifen));
              }
            }
          }
          console.log(newArr);
          this.parent_id = newArr[0];

          // } else {
          //   this.parent_id = this.item;
          //   console.log(this.parent_id);
          // }

          this.isDisabled = false;
          this.placeholderTxt = "请选择";
          this.$emit("isDisabledFun", false);
          return this.tableData;
        }
      });
    },
    // key：对比的id ， arrData：原始数组
    changeCascader(key, arrData) {
      let arr = [];
      let returnArr = []; // 存放结果的数组
      let depth = 0; // 定义全局层级
      // 定义递归函数

      function childrenEach(childrenData, depthN) {
        for (var j = 0; j < childrenData.length; j++) {
          depth = depthN; // 将执行的层级赋值 到 全局层级
          arr[depthN] = childrenData[j].id;
          if (childrenData[j].id == key) {
            returnArr = arr.slice(0, depthN + 1); //将目前匹配的数组，截断并保存到结果数组，
            break;
          } else {
            if (childrenData[j].children) {
              depth++;
              childrenEach(childrenData[j].children, depth);
            }
          }
        }
        return returnArr;
      }

      return childrenEach(arrData, depth);
    },
    filterDep(data) {
      data.forEach((list, index) => {
        if (list.childs && list.childs.length) {
          list.children = this.filterDep(list.childs);
        }
        list.value = list.id;
        list.label = list.name;
        return { ...list };
      });

      return data;
    },

    chaifen(treeView) {
      const view = [];
      function extractData(arr, pid) {
        let order = 1;
        for (let i = 0; i < arr.length; i++) {
          if (arr[i].children) {
            extractData(arr[i].children, arr[i].id);
            delete arr[i].children;
          }
          view.push({
            pid,
            ...arr[i],
            order: order++
          });
        }
      }
      extractData(treeView, 0);
      return view;
    },
    zhaoFu(item, chaifen) {
      let view = [];
      view.unshift(item.id);
      function extractData(arr, chaifen) {
        if (arr.parent_id * 1 != 0) {
          let obj = chaifen.find(o => o.id == arr.parent_id);
          view.unshift(obj.id);
          extractData(obj, chaifen);
        }
      }
      extractData(item, chaifen);
      return view;
    }
  }
};

export default deptMixins;
